import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function SideBar({ agentLogoUrl }) {
    return (
        <Box component="div" sx={{backgroundColor: '#001E37', color: 'white', height: '100%', position: 'relative', px: 3}}>
            
            <Box component="div" sx={{ p: 0, display: 'flex', alignItems: 'center', pt: '35vh', mb: 4 }}>
                <div className="convera-img-wrapper">
                    <img src="/images/convera-logo.png" alt="Conver logo" className="logo-img" />
                </div>
                <div className="banner-img-wrapper">
                    <img src={agentLogoUrl} alt="" className="banner-img" />
                </div>
            </Box>
            <Typography variant="h1" gutterBottom>
                Counsellor Tracking <br></br> Form
            </Typography>
            <div className="need-help">
                <span>Need help? <a href="#">Contact us</a></span>
            </div>
            
            
        </Box>
    );
}

export default SideBar;