import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import { axiosClient } from "../../axios.config";
import { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';


const PATTERNS = {
    email: /^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    name: /^[A-Za-z ]+$/i
}

const ERROR_MESSAGES = {
    required: "This field is required",
    email: "Please enter a valid email",
    payRef: "Please enter a valid payment reference",
    name: "Please enter a valid name",
    deal_mismatch: "Payment Reference and Student Email Address must match our records to complete submission of form. Please confirm details and attempt again.",
    deal_claimed: "This payment record is already associated with a counsellor. Please enter unclaimed payment record details only.",
    deal_valid: "Payment Reference and Student Email Address have been validated."
}

function AgentForm({ agentID, setLoading }) {
    const { register, handleSubmit, control, formState: { errors, isValid, isSubmitting }, getValues, setValue, reset } = useForm({
        mode: 'onBlur'
    });
    const recaptchaRef = useRef(null);
    const [dealValidationMsg, setDealMessage] = useState('');
    const [isDealValid, setDealValid] = useState(false);
    const [dealRefLoading, setDealLoading] = useState(false);
    const [studentEmailLoading, setStudentEmailLoading] = useState(false);

    const onSubmit = data => {
        recaptchaRef.current.execute();
    };

    const handleRecaptchaChange = (response) => {
        if (response) {
            submitForm(response);
        } else {
            // display captcha invalid/not verified error
        }
    };

    const submitForm = (recaptchaResponse) => {
        const formData = getValues();

        setLoading(true);
        axiosClient.post(`/geo-buyer/services/agent/affiliation/submit`, {
            ...formData,
            affiliateIdentifier: agentID,
            recaptchaResponse,
            counsellorId: ''
        })
            .then(res => {
                setLoading(false);
                if (!res.data.errorCode) {
                    setDealMessage('');
                    setDealValid(false);
                    reset();
                } else {
                    //show correposnding error
                }
            })
            .catch(err => console.error(err))
    }

    const validateDealRef = async (triggeredBy) => {
        let [dealReference, studentEmail] = getValues(['dealReference', 'studentEmail']);

        let data = {
            affiliateIdentifier: agentID,
            dealReference,
            studentEmail
        }

        triggeredBy === 'dealReference' ? setDealLoading(true) : setStudentEmailLoading(true)

        return axiosClient.post(`/geo-buyer/services/agent/affiliation/deal/validate`, data)
            .then(res => {
                triggeredBy === 'dealReference' ? setDealLoading(false) : setStudentEmailLoading(false)
                if (res.data.validDeal) {
                    setValue('institutionName', res.data.institutionName)
                    setDealMessage(ERROR_MESSAGES.deal_valid);
                    setDealValid(true);
                    return true;
                } else {
                    setValue('institutionName', '')
                    setDealMessage(ERROR_MESSAGES.deal_mismatch);
                    setDealValid(false);
                    return false;
                }
            })
            .catch(err => console.error(err))
        
    }

    return (
        <form className="agent-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h3" gutterBottom onClick={() => validateDealRef()}>
                Personal information
            </Typography>
            <Grid container spacing={3} sx={{mb: 2}}>
                <Grid item xs={12} sm={6}>
                    <label>Payment reference number*</label>

                    <TextField label="" variant="outlined"
                        inputProps={{ maxLength: 28, placeholder: "Payment reference number*" }}
                        error={!!errors.dealReference}
                        helperText={errors?.dealReference?.message}
                        //InputProps={{
                            //endAdornment: dealRefLoading  ? <InputAdornment position="start" sx={{backgroundColor: 'white'} }><CircularProgress size={15} /></InputAdornment> : '',
                        //}}
                        fullWidth {...register("dealReference", {
                            required: ERROR_MESSAGES.required,
                            minLength: {
                                value: 10,
                                message: ERROR_MESSAGES.payRef
                            },
                            validate: () => validateDealRef('dealReference')
                        })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Student email address*</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Student email address*" }}
                        //InputProps={{
                            //endAdornment: studentEmailLoading ? <InputAdornment position="start" sx={{ backgroundColor: 'white' }}><CircularProgress size={15} /></InputAdornment> : '',
                        //}}
                        error={!!errors.studentEmail}
                        helperText={errors?.studentEmail?.message}
                        fullWidth {...register("studentEmail", {
                            required: ERROR_MESSAGES.required,
                            pattern: {
                                value: PATTERNS.email,
                                message: ERROR_MESSAGES.email
                            },
                            validate: () => validateDealRef('studentEmail')
                        })} />
                </Grid>

                <Grid item xs={12}>
                    <FormHelperText error={!isDealValid} sx={{ position: 'relative', bottom: 0 }}>{dealValidationMsg}</FormHelperText>
                    <label>Institution name</label>
                    <Controller
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label=""
                                inputProps={{ placeholder: "Institution name" }}
                                disabled
                                fullWidth
                            />
                        )}
                        control={control}
                        name="institutionName"
                        defaultValue=""
                    />
                </Grid>
            </Grid>


            <Typography variant="h3" gutterBottom>
                Counsellor information
            </Typography>
            <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                    <label>Counsellor name*</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Counsellor name*" }}
                        error={!!errors.counsellorName}
                        helperText={errors?.counsellorName?.message} 
                        fullWidth {...register("counsellorName", {
                            required: ERROR_MESSAGES.required,
                            pattern: {
                                value: PATTERNS.name,
                                message: ERROR_MESSAGES.name
                            }
                        })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Counsellor email*</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Counsellor email*" }}
                        error={!!errors.counsellorEmail}
                        helperText={errors?.counsellorEmail?.message}                        
                        fullWidth {...register("counsellorEmail", {
                            required: ERROR_MESSAGES.required,
                            pattern: {
                                value: PATTERNS.email,
                                message: ERROR_MESSAGES.email
                            }
                        })} />
                </Grid>
               
                <Grid item xs={12} sm={6}>
                    <label>Agency name*</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Agency name*" }}
                        error={!!errors.agencyName}
                        helperText={errors?.agencyName?.message} 
                        fullWidth {...register("agencyName", {
                            required: ERROR_MESSAGES.required,
                            pattern: {
                                value: PATTERNS.name,
                                message: ERROR_MESSAGES.name
                            }
                        })} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <label>Agency address*</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Agency address*" }}
                        error={!!errors.agencyAddress}
                        helperText={errors?.agencyAddress?.message}
                        fullWidth {...register("agencyAddress", {
                            required: ERROR_MESSAGES.required,
                        })} />
                </Grid>
                <Grid item xs={12}>
                    <label>Comments (optional)</label>
                    <TextField label="" variant="outlined"
                        inputProps={{ placeholder: "Comments (optional)" }}
                        fullWidth {...register("comments")} />
                </Grid>
            </Grid>

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                size="invisible"
                onChange={handleRecaptchaChange}
            />

            <Button variant="contained" className="submit-button" type="submit" disableElevation disabled={isSubmitting || !isValid}>Submit</Button>
        </form>
    );
}

export default AgentForm;