import './App.scss';
import Footer from "./components/Footer";
import Home from './modules/Home/index';
import Container from '@mui/material/Container';

function App() {
  return (
    <Container component="main" maxWidth="lg" sx={{ mb: 4 }} className="main-wrapper">
        <Home />
    </Container>
  );
}

export default App;
