import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
            fontSize: 24,
            fontWeight: '600'
        },
        h3: {
            fontSize: 16,
            color: '#001E37',
            fontWeight: '600',
        },
        p: {
            fontSize: 14,
            color: '#001E37',
            fontWeight: '400',
        },
        subtitle2: {
            fontSize: 12,
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: 700,
                    '&.Mui-disabled': {
                        backgroundColor: "#00C7B3",
                        opacity: 0.9
                    }
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white', 
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '0px !important',
                },
                input: {
                    backgroundColor: 'white',
                    height: '0.9em',
                    borderRadius: '0',
                    fontSize: '14px',
                    '&::before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)' // use your color
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "transparent"
                    }
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    bottom: -25
                },
            }
        }
    },
    palette: {
        primary: {
            main: '#00C7B3',
        },
        secondary: {
            main: '#001E37',
        },
    },
});

export default theme;