import Typography from '@mui/material/Typography';

function Footer() {
    return (
        <div className="footer-wrapper">
            <Typography variant="caption" sx={{py: 1}}>
                Convera UK Financial Limited (registered in England, Company Number 13682869,
                Registered Office Address: Alphabeta Building, 14-18 Finsbury Square, 3rd Floor, London, EC2A 1AH)
                is: (i) authorised and regulated by the Financial Conduct Authority; (ii) authorised by the
                Financial Conduct Authority under the Payment Services Regulations 2017 for the provision of
                payment services.
            </Typography>
        </div>
    );
}

export default Footer;